import { getAuth, onAuthStateChanged } from "firebase/auth"
import { g } from "../../../../../utils"

class User {
    constructor(app) {
        this.app = app
    }

    async init(signedInCallback, signedOutCallback) {
        const auth = getAuth(this.app)
        
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const profileImage = g("profile_image")

                if(profileImage) {
                    profileImage.src = user.photoURL
                }
                
                signedInCallback(user)
            } else {
                signedOutCallback()
            }
        })
    }
}

export { User }